<template>
  <div
    v-if="$route.name === 'index'"
    class="w-block__footer"
    :class="{
      'w-block__footer--mobile': $voicer.isMobile,
      'w-block__footer--ios': $voicer.isIos,
      'w-block__footer--no-player': isPersistantVisible === false,
    }"
  >
    <!-- footer block -->
    <div class="pa-4">
      <div class="body-2 mb-3">Avec la participation de :</div>
      <div class="mt-1 mb-4">
        <img
          :src="require('~/assets/images/phoenixattitude.jpg')"
          height="49"
          alt="logo-phoenisattitude"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/associationdesnanas.png')"
          height="49"
          alt="logo-associationdesnanas"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/audeladucancer.png')"
          height="49"
          alt="logo-audeladucancer"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/discamion.png')"
          height="49"
          alt="logo-discamion"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/jeunetrose.png')"
          height="49"
          alt="logo-jeuneetrose"
          class="mr-4"
        />
      </div>
      <hr class="mt-4 mb-4" size="1" color="#b80e7d" />
      <div class="body-2 mb-2">avec le soutien institutionnel de :</div>
      <div>
        <img
          :src="require('~/assets/images/aesio.png')"
          height="39"
          alt="logo-aesio"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/unitedheroes.png')"
          height="39"
          alt="logo-unitedheroes"
          class="mr-4"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__footer {
  justify-content: center;
  text-align: center;
  border-top-style: solid;
  border-top-color: #b80e7d;
  background-color: white;
  padding-bottom: 50px;
  color: #b80e7d;
  &--mobile {
    padding-bottom: 140px;
  }
  &--ios {
    padding-bottom: 150px;
  }
  &--no-player {
    padding-bottom: 30px;
  }
}
hr {
  height: 1px;
  background-color: #b80e7d;
  width: 40%;
  margin: 1px;
  border: none;
  margin: auto;
}
</style>
